.weather-text {
  font-family: 'roboto-medium';
  font-size: 8px;
  color: #85857F;
  line-height: 3px;
}
.weather-icon {
  font-size: 22px;
  color: #85857F;
}
.project-title {
  width: '100%';
  font-family: 'roboto-bold';
  font-size: 16px;
}
.project-subtitle {
  font-family: 'roboto-light';
  font-size: 12px;
  color: #85857F;
}
.project-light-title,
.project-dark-title {
  font-family: 'roboto-regular';
  font-size: 14px;
}
.project-dark-title,
.project-dark-subtitle {
  color: #323232;
}
.project-light-title,
.project-light-subtitle {
  color: #85857F;
}
.project-dark-subtitle,
.project-light-subtitle {
  font-family: 'roboto-medium';
  font-size: 14px;
}
.project-progress-container {
  position: absolute;
  top: 101px;
  bottom: 53px;
  left: 24px;
  right: 24px;
  padding-top: 10px;
  padding-bottom: 50px;
  overflow-y: auto;
}
.project-progress-container.contract-container {
  overflow: hidden;
  padding-bottom: 0px;
}
.progress-diff {
  position: absolute;
  font-size: 10px;
  width: 100px;
  top: -15px;
  left: -35px;
  right: 0px;
  white-space: nowrap;
  text-align: center;
}
.project-add-costcode-container {
  position: absolute;
  top: 120px;
  left: 20px;
  right: 20px;
  bottom: 50px;
  overflow-x: auto;
}
.project-add-costcode-list-header {
  width: 100%;
  padding: 10px 20px;
  position: relative;
}
.project-add-costcode-header-title {
  font-size: 18px;
  font-family: 'roboto-medium', sans-serif;
}
.project-add-costcode-list-container {
  border: 1px lightgray solid;
  border-radius: 4px;
  margin: 10px 0px;
}
.project-add-costcode-table {
  overflow: hidden;
}
.project-add-global-costcode-list {
  position: absolute;
  top: 56px;
  left: 0px;
  right: 0px;
  bottom: 53px;
  overflow-y: auto;
}
.project-add-equipment-table {
  overflow: hidden;
  position: absolute;
  inset: 0px;
  top: 50px;
}
.project-add-equipment-list-header {
  width: 100%;
  padding: 10px 20px;
  position: relative;
  border-bottom: 1px lightgray solid;
}
.project-equipment-table-row:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.project-contract-header {
  display: inline-flex;
  width: 100%;
  padding-bottom: 10px;
}
.project-contract-header h3 {
  margin-right: auto;
}
.project-contract-header button {
  margin-left: 10px;
}
tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.schedule-of-values-form .ant-typography {
  margin-bottom: 0px;
}
.schedule-of-values-form .ant-form-item-label {
  margin-top: 0px !important;
}
.schedule-of-values-review-drawer .footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}
.schedule-of-values-review-drawer .footer .OnTraccrInput {
  width: 50% !important;
}
.schedule-of-values-review-drawer .footer button {
  margin-left: 10px;
}
.project-contract-body {
  overflow-y: auto;
  height: calc(100% - 210px);
}
.project-contract-body .ant-input-number {
  width: 100% !important;
}
.contract-separator {
  background: linear-gradient(to right, #48439d, #bf381d);
  height: 2px;
  width: 100%;
}
.history-notes-container {
  padding-bottom: 10px;
}
.history-notes-header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.history-notes-header button {
  margin-left: auto;
}
.progress-history-notes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-history-notes-header button {
  margin: 3px;
}
.project-edit-drawer .ant-drawer-content-wrapper {
  max-width: 1400px !important;
  width: 100% !important;
}
.log-table-row .ant-table-cell {
  border-bottom: none;
  border-top: 1px solid #f0f0f0;
}
.log-table-file-row .ant-row {
  overflow-x: auto !important;
}
.sov-pdf-preview-frame {
  width: 100%;
  height: calc(100% - 120px);
}
.schedule-of-value-pdf-form {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.schedule-of-value-pdf-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
}
.schedule-of-value-pdf-form-row .schedule-of-value-pdf-form-row-label {
  font-weight: 650;
  padding-right: 5px;
}
.schedule-of-value-pdf-form-row .ant-form-item {
  padding-right: 10px;
  margin-bottom: 0px;
}
.project-contract-switch {
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 0px !important;
}
.project-contract-switch .ant-form-item-label {
  padding-bottom: 0px !important;
}
.project-form-template-container {
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  bottom: 53px;
  padding: 20px;
  background-color: #f0f2f5;
}
.project-sov-collapse .ant-collapse-header {
  display: flex;
  align-items: center;
  background: #f2b601;
  font-weight: 650;
}
.project-sov-collapse .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.project-sov-collapse .ant-collapse-content-box {
  padding: 0px !important;
}
.ant-collapse-header {
  display: flex;
  align-items: center;
}
.ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.live-summary-table {
  border: 1px solid #d9d9d9;
  border-bottom: none;
}
.live-summary-table .ant-table-cell {
  text-align: center;
}
.sov-invalid-value {
  color: red;
}
.project-progress-table .ant-table-tbody > tr > td {
  padding: 10px 10px;
}
.project-progress-summary-legend {
  display: inline-flex;
  font-weight: 650;
  padding-right: 15px;
}
.project-progress-summary-legend .project-progress-summary-legend-item {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.project-group-project-list .customer-project-container {
  padding: 0 24px;
}
.OnTraccrSelect {
  border-radius: 6px;
  color: #323232;
  font-family: 'roboto-regular';
  width: 100%;
}
.OnTraccrSelect:active,
.OnTraccrSelect:hover,
.OnTraccrSelect:focus {
  border-color: #323232;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.OnTraccrSelect::selection {
  background-color: #323232;
  color: white;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector,
.ant-select-selector:active,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-select-selector::selection,
.ant-select-focused.ant-select-multiple .ant-select-selector {
  border-color: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border-radius: inherit;
  font-family: inherit;
  color: inherit;
}
.ant-select-multiple .ant-select-selector {
  border-radius: inherit;
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab-active:hover {
  color: #BF381D !important;
}
.ant-tabs-ink-bar {
  background-color: #BF381D;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  opacity: 0.75;
  color: #323232;
}
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-nav,
.upload-format-text {
  font-family: 'roboto-regular';
}
.upload-format-text {
  color: #323232;
  margin-top: 10px !important;
}
.template-link-text {
  color: #323232;
}
.template-link-text:hover {
  opacity: 0.7;
  color: inherit;
}
.ant-upload-list {
  margin-bottom: 20px;
}
.ant-upload-list-item {
  font-family: 'roboto-light';
}
.ant-upload-list-item:hover {
  background-color: inherit;
  opacity: 0.5;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: inherit;
}
.ant-tabs-tabpane:focus {
  outline: none;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #323232;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #F2BD1E;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #F2BD1E;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #F2BD1E;
}
.ant-steps-small .ant-steps-item-title {
  font-family: 'roboto-regular';
}
.ant-picker {
  border-radius: 6px;
}
.ant-picker-focused {
  box-shadow: none;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #323232;
}
.ant-picker-range .ant-picker-active-bar,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #BF381D;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after {
  border-color: #BF381D;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #BF381D;
  opacity: 0.3;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background-color: #BF381D;
  opacity: 0.75;
}
.ant-picker-header-view button:hover {
  color: #BF381D;
}
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background-color: inherit;
  opacity: 0.65;
  color: #BF381D;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector {
  border-radius: 6px !important;
  font-family: 'roboto-regular';
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: inherit;
  color: #BF381D;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  opacity: 0.75;
  background-color: inherit;
}
.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #BF381D;
}
.ant-slider-handle,
.ant-slider-handle:focus,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #BF381D;
}
.ant-slider-handle:focus {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #BF381D;
}
.ant-switch:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:hover {
  border-color: #323232;
}
.ant-input {
  padding-left: 15px;
}
.ant-transfer-list-search-action {
  right: 18px;
}
.ant-transfer-list-search {
  border-radius: 20px;
}
.ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
  background-color: inherit;
}
.ant-input-group.ant-input-addOnAfterInput {
  display: inline-flex !important;
}
.ant-input-group.ant-input-addOnAfterInput .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group.ant-input-addOnAfterInput .ant-input-group-addon {
  width: 100px;
}
.ant-input-group.ant-input-addOnAfterInput .ant-input-group-addon .ant-select {
  top: 3px;
}
.ant-select.select-warning .ant-select-selector {
  background: #F2BD1E;
}
.button-select-input .ant-btn {
  border-radius: 7px 0px 0px 7px !important;
  border-right: none;
  background: #BF381D;
  color: white;
}
.button-select-input .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.button-select-input .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.button-select-input .ant-select-selector {
  border-radius: 0px 6px 6px 0px !important;
}
.OnTraccrBtn-back,
.OnTraccrBtn-back:disabled,
.OnTraccrBtn-cancel,
.OnTraccrBtn-secondary,
.OnTraccrBtn-primary {
  border-radius: 6px;
  text-shadow: none;
}
.OnTraccrBtn-back:hover:not([disabled]) {
  opacity: 0.5 !important;
}
.OnTraccrBtn-back,
.OnTraccrBtn-back:disabled,
.OnTraccrBtn-back:disabled:hover,
.OnTraccrBtn-back:active,
.OnTraccrBtn-back:focus,
.OnTraccrBtn-back:hover {
  color: #BF381D !important;
  border-color: #BF381D !important;
  background-color: white !important;
}
.OnTraccrBtn-secondary,
.OnTraccrBtn-secondary:active,
.OnTraccrBtn-secondary:focus,
.OnTraccrBtn-secondary:hover {
  color: black !important;
  background-color: #F2BD1E !important;
  border-color: #F2BD1E !important;
  text-shadow: none !important;
}
.OnTraccrBtn-secondary:hover {
  opacity: 0.75 !important;
}
.ant-btn-primary,
.OnTraccrBtn-primary,
.OnTraccrBtn-primary:active,
.OnTraccrBtn-primary:focus,
.OnTraccrBtn-primary:disabled,
.OnTraccrBtn-primary:disabled:hover {
  background-color: #BF381D;
  border-color: #BF381D;
  color: white;
}
.OnTraccrBtn-primary:hover,
.ant-btn-primary:hover {
  background-color: #BF381DC8;
  border-color: #BF381DC8;
}
.OnTraccrBtn-primary:disabled,
.OnTraccrBtn-primary:disabled:hover {
  opacity: 0.5;
}
.OnTraccrBtn-cancel,
.OnTraccrBtn-cancel:active,
.OnTraccrBtn-cancel:focus {
  color: white;
  background-color: gray;
  border-color: gray;
}
.OnTraccrBtn-cancel:hover {
  opacity: 0.5;
  background-color: gray;
  border-color: gray;
  color: white;
}
.OnTraccrBtn-cancel:disabled,
.OnTraccrBtn-cancel:disabled:hover {
  opacity: 0.5;
}
.ant-picker-today-btn {
  color: #BF381D;
}
.ant-picker-today-btn:hover {
  color: #BF381DC8;
}
.ant-btn-sm:hover:not(.ant-btn-primary) {
  opacity: 0.65;
  border-color: inherit;
  color: inherit;
}
.more-button {
  cursor: pointer;
}
.ant-dropdown-menu-item-selected {
  background-color: inherit;
  color: inherit;
}
.ant-dropdown-menu-item:hover {
  background-color: inherit;
  opacity: 0.45;
}
.example-enter {
  opacity: 0.01;
}
.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.card-shadow {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.breadcrumb-link {
  padding: 3px;
  border-radius: 4px;
}
.breadcrumb-link:hover,
.breadcrumb-link:active {
  color: black !important;
  background-color: lightgray;
  opacity: 0.65;
}
.drawer-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: #e9e9e9 1px solid;
  padding: 10px 16px;
  background: white;
  text-align: right;
}
.date-range-picker-icon {
  font-size: 20px;
  margin-left: 0px !important;
  margin-top: 3px;
  color: black;
}
.tag-view {
  background-color: rgba(198, 198, 198, 0.15);
  color: #BF381D;
}
.listview-table-scroll {
  cursor: pointer;
}
.listview-table-scroll:hover {
  background-color: #F8F8F8;
}
.slider-download-button {
  position: absolute;
  top: 0;
  right: 40px;
  width: 56px;
  height: 54px;
  padding: 18px;
  margin: 0;
}
.custom-field-container {
  display: flex;
  flex: 1;
  position: relative;
}
.custom-field-container .ant-input-disabled.OnTraccrInput {
  background: #fff;
  color: #000;
  border: none;
  padding: 0;
  cursor: default;
}
.custom-field-container .ant-select-disabled .ant-select-selector {
  background: #fff !important;
  color: #000 !important;
  border: none;
  padding: 0;
  cursor: default !important;
}
.custom-field-container .ant-select-disabled.ant-select .ant-select-arrow {
  display: none;
}
.bordered-container-body {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: #5C5C56 1px solid;
  padding: 15px;
}
.collapsable-container {
  border: 1px solid lightgray;
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
}
.collapsable-header {
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding: 0px 10px;
  margin: 0px;
}
.collapsable-contents-container {
  background-color: rgba(198, 198, 198, 0.15);
  padding-bottom: 0px;
  overflow: auto;
}
.header-hover {
  transition: transform 0.5s ease;
}
.header-hover:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}
.header-container {
  cursor: pointer;
  font-weight: bold;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  color: white;
  background-color: #85857F;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  transition: transform 0.5s ease;
}
.header-container:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}
#header-profile-icon {
  cursor: pointer;
  transition: transform 0.5s ease;
}
#header-profile-icon:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}
.header-profile-button {
  cursor: pointer;
  width: 150px;
}
.header-profile-button:hover {
  opacity: 0.75;
}
.header-dropdown-divider {
  width: 150px;
  margin: 10px 0px;
}
.ant-picker-week-panel {
  width: 300px;
}
.ant-picker-week-panel-row-selected td,
.ant-radio-inner::after {
  background-color: #BF381D;
}
.ant-picker-week-panel-row-selected:hover td {
  background-color: #BF381DC8;
}
.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked::after {
  border-color: #BF381D;
}
.ant-radio-input {
  outline-color: #BF381D;
}
.ontraccr-dark-report-header {
  background-color: #323232 !important;
}
.ontraccr-last-updated-report {
  border: none !important;
  background-color: #f0f2f5 !important;
  text-align: end !important;
}
.ontraccr-row:hover {
  background-color: #fafafa;
}
.ontraccr-cell {
  word-wrap: break-word;
  word-break: break-word;
}
.sage-export-preview-table {
  border: 1px solid lightgray;
  border-radius: 8px;
  position: absolute;
  top: 75px;
  left: 24px;
  right: 25px;
  bottom: 75px;
  overflow: hidden;
}
.sage-export-warning-bold {
  font-family: roboto-regular, sans-serif, monospace;
  font-weight: bold;
  color: black;
}
.antd-table-cell-render {
  word-wrap: break-word;
  word-break: break-word;
}
.register-password {
  color: white;
  border-radius: 0px;
}
.register-password::selection,
.dark-register::selection {
  background-color: #BF381DC8;
}
.register-select .ant-select-selection-placeholder {
  opacity: 0.7;
}
.get-started-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: top 0.5s ease-in-out;
}
.signup-card {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px #5C5C56 solid;
  border-color: #5C5C56;
  width: 450px;
  min-height: 400px;
  position: relative;
  font-family: roboto-regular, sans-serif;
  font-size: 24px;
}
.nux-screenshot {
  box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.16);
}
.nux-focus-background {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: '100%';
  height: '100%';
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.4);
}
.ant-collapse-header {
  background-color: white;
}
.ontraccr-collapse-panel {
  background: #F8F8F8;
  border-radius: 2px;
  border: 0px;
  overflow: scroll;
}
.livefeed-text-input {
  resize: none;
  border-radius: 6px;
  padding-left: 40px;
  padding-right: 70px;
  min-height: 34px;
  border: none;
  line-height: 23px !important;
  caret-color: black;
  margin-bottom: 0px !important;
}
.livefeed-text-input-tags {
  resize: none;
  border-radius: 6px;
  padding-left: 40px;
  padding-right: 70px;
  min-height: 34px;
  border: none;
  line-height: 23px !important;
  caret-color: black;
  margin-bottom: 0px !important;
  background-color: transparent;
  color: transparent;
}
.livefeed-text-input-no-tags {
  resize: none;
  border-radius: 6px;
  padding-left: 40px;
  padding-right: 70px;
  min-height: 34px;
  border: none;
  line-height: 23px !important;
  caret-color: black;
  margin-bottom: 0px !important;
  max-height: 70vh !important;
  background-color: white;
}
.livefeed-text-input:focus,
.livefeed-text-input:hover {
  outline: none;
}
.livefeed-text-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  line-height: 22px;
  pointer-events: none;
  background-color: white;
  align-self: baseline;
  overflow: hidden;
  min-height: 34px;
  padding-left: 40px;
  padding-right: 70.5px;
  overflow-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 6px;
}
.livefeed-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  min-height: 34px;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 0px 20px;
  min-width: 72px;
}
.livefeed-text-container {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px lightgray solid;
  overflow-y: auto;
}
.livefeed-text-container:hover,
.livefeed-text-container:active,
.livefeed-text-container:focus {
  border: 1px black solid;
}
.livefeed-shadow {
  -webkit-box-shadow: 0px 0px 8px 2px rgba(100, 100, 100, 0.7);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 0px 8px 2px rgba(100, 100, 100, 0.7);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 0px 8px 2px rgba(100, 100, 100, 0.7);
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  margin-left: 5px;
  margin-right: 5px;
  height: 0.1px;
  margin-top: -1px;
}
.livefeed-message-icon {
  margin-right: 5px;
  position: absolute;
  left: 24px;
  bottom: 3px;
  width: 24px;
  height: 26px;
  box-shadow: none;
  color: lightgray;
  border: none;
  padding: 0px;
  cursor: default;
}
.livefeed-message-icon:active,
.livefeed-message-icon:focus,
.livefeed-message-icon:hover {
  color: lightgray;
}
.livefeed-message-icon-collapsable {
  margin-right: 5px;
  position: absolute;
  left: 24px;
  bottom: 3px;
  width: 24px;
  height: 26px;
  box-shadow: none;
  color: lightgray;
  border: none;
  padding: 0px;
  cursor: default;
  cursor: pointer;
}
.livefeed-message-icon-collapsable:active,
.livefeed-message-icon-collapsable:focus,
.livefeed-message-icon-collapsable:hover {
  color: lightgray;
}
.livefeed-message-icon-collapsable:hover,
.livefeed-message-icon-collapsable:active,
.livefeed-message-icon-collapsable:focus {
  border-color: #BF381D !important;
}
.livefeed-message-icon-collapsable:active,
.livefeed-message-icon-collapsable:focus {
  color: lightgray;
}
.livefeed-message-icon-collapsable:hover {
  color: black !important;
}
.livefeed-send-icon {
  margin-right: 5px;
  position: absolute;
  right: 22px;
  bottom: 3px;
  width: 24px;
  height: 26px;
  border: none;
  box-shadow: none;
  padding: 0px;
}
.livefeed-send-icon:active,
.livefeed-send-icon:focus,
.livefeed-send-icon:hover {
  border-color: #BF381D !important;
  color: #BF381D !important;
}
.livefeed-send-icon:hover {
  opacity: 0.65;
}
.livefeed-send-icon:disabled {
  background-color: inherit;
}
.livefeed-send-icon:disabled:hover {
  opacity: 0.5;
}
.livefeed-file-icon {
  margin-right: 5px;
  position: absolute;
  right: 50px;
  bottom: 3px;
  width: 24px;
  height: 26px;
  border: none;
  box-shadow: none;
  padding: 0px;
}
.livefeed-file-icon:active,
.livefeed-file-icon:focus,
.livefeed-file-icon:hover {
  border-color: #BF381D !important;
  color: #BF381D !important;
}
.livefeed-file-icon:hover {
  opacity: 0.65;
}
.livefeed-file-icon:disabled {
  background-color: inherit;
}
.livefeed-file-icon:disabled:hover {
  opacity: 0.5;
}
.livefeed-tag-list {
  position: absolute;
  background-color: white;
  bottom: 40px;
  width: 300px;
  border-radius: 4px;
  border: 1px solid black;
  overflow-y: scroll;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(100, 100, 100, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 0px 10px 3px rgba(100, 100, 100, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 0px 10px 3px rgba(100, 100, 100, 0.25);
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.livefeed-tag-row {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  padding-bottom: 10px;
}
.livefeed-tag-row:hover {
  opacity: 0.65;
}
.live-feed-file-upload {
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 6px;
}
.ant-upload.ant-upload-drag {
  background: rgba(200, 200, 200, 0.4);
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #BF381D;
}
.live-feed-file-upload-button {
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  background-color: transparent;
}
.live-feed-file-upload-button:hover {
  opacity: 0.65;
}
.live-feed-file-upload-button:active,
.live-feed-file-upload-button:focus,
.live-feed-file-upload-button:hover {
  border-color: #BF381D !important;
  color: inherit;
}
.livefeed-file-reel-container {
  margin-bottom: 10px;
  background-color: transparent;
}
.livefeed-file-picker {
  position: absolute;
  top: 46px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 10px;
}
.livefeed-menu .ant-drawer-header {
  padding: 10px 24px;
}
.livefeed-menu .ant-drawer-body {
  padding: 0px;
  padding-left: 15px;
}
.livefeed-menu .ant-drawer-body .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: 0px;
  left: 1.5px;
}
.livefeed-menu .ant-drawer-body .ant-menu-submenu-title:hover {
  color: #F2BD1E;
}
.livefeed-menu .ant-drawer-body .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.livefeed-menu .ant-drawer-body .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #F2BD1E;
}
.livefeed-menu .ant-drawer-body .ant-menu-item::after {
  border-right: 0px;
}
.livefeed-menu .ant-drawer-body .ant-menu-submenu-selected {
  color: #F2BD1E;
}
.livefeed-menu .ant-drawer-body .livefeed-menu-item-custom-channel:hover,
.livefeed-menu .ant-drawer-body .ant-menu-item-selected {
  color: #F2BD1E !important;
  background-color: rgba(198, 198, 198, 0.15) !important;
}
.livefeed-menu .ant-drawer-body .livefeed-menu-item-custom-channel:hover .livefeed-menu-item-custom-channel-settings,
.livefeed-menu .ant-drawer-body .ant-menu-item-selected .livefeed-menu-item-custom-channel-settings {
  opacity: 1 !important;
}
.livefeed-menu-divider {
  margin: 2px 0px;
  background-color: #f0f0f0;
}
.livefeed-menu-item-custom-channel-settings {
  opacity: 0;
}
.photo-preview-container {
  margin: 10px 5px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: lightgrey 1px solid;
}
.photo-shadow {
  -webkit-box-shadow: 0px 0px 5px 2px rgba(100, 100, 100, 0.3);
  -moz-box-shadow: 0px 0px 5px 2px rgba(100, 100, 100, 0.3);
  box-shadow: 0px 0px 5px 2px rgba(100, 100, 100, 0.3);
}
.photo-preview,
.photo-preview-small {
  position: absolute;
  border-radius: 4px;
  width: auto;
  background-color: white;
  object-fit: contain;
}
.photo-preview-delete {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 20px;
  width: 20px;
  border-radius: calc(20px/2);
  background-color: #BF381D;
  line-height: 12px;
  border: none;
}
.photo-preview-delete:hover,
.photo-preview-delete:focus,
.photo-preview-delete:active {
  background-color: #BF381D;
  border-color: #BF381D !important;
  color: #BF381D !important;
}
.photo-preview-delete:hover {
  opacity: 0.8;
}
.photo-preview-delete-icon {
  color: white;
  font-size: 12px;
}
.full-photo-background {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}
.invoice-upload-contained-full-photo-background {
  max-height: calc(90vh - 300px);
  position: relative;
}
.completed-form-contained-full-photo-background {
  max-height: calc(90vh - 50px);
  position: relative;
}
.full-photo {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  background-color: white;
  margin-top: 50px;
}
.full-photo-title {
  position: fixed;
  left: 60px;
  top: 15px;
  color: white;
  font-size: 18px;
}
.full-photo-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
}
.full-photo-button:hover {
  opacity: 0.9;
}
.full-photo-button:active,
.full-photo-button:focus,
.full-photo-button:hover {
  border-color: black !important;
  color: inherit;
}
.full-photo-left-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: fixed;
  left: 50px;
  top: 50%;
}
.full-photo-left-button:hover {
  opacity: 0.9;
}
.full-photo-left-button:active,
.full-photo-left-button:focus,
.full-photo-left-button:hover {
  border-color: black !important;
  color: inherit;
}
.full-photo-right-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: fixed;
  right: 50px;
  top: 50%;
}
.full-photo-right-button:hover {
  opacity: 0.9;
}
.full-photo-right-button:active,
.full-photo-right-button:focus,
.full-photo-right-button:hover {
  border-color: black !important;
  color: inherit;
}
.full-photo-close-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: fixed;
  right: 50px;
  top: 50px;
}
.full-photo-close-button:hover {
  opacity: 0.9;
}
.full-photo-close-button:active,
.full-photo-close-button:focus,
.full-photo-close-button:hover {
  border-color: black !important;
  color: inherit;
}
.invoice-upload-contained-full-photo-close-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: absolute;
  top: 10px;
  right: 40px;
}
.invoice-upload-contained-full-photo-close-button:hover {
  opacity: 0.9;
}
.invoice-upload-contained-full-photo-close-button:active,
.invoice-upload-contained-full-photo-close-button:focus,
.invoice-upload-contained-full-photo-close-button:hover {
  border-color: black !important;
  color: inherit;
}
.completed-form-contained-full-photo-close-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: absolute;
  top: 10px;
}
.completed-form-contained-full-photo-close-button:hover {
  opacity: 0.9;
}
.completed-form-contained-full-photo-close-button:active,
.completed-form-contained-full-photo-close-button:focus,
.completed-form-contained-full-photo-close-button:hover {
  border-color: black !important;
  color: inherit;
}
.full-photo-download-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: fixed;
  right: 100px;
  top: 50px;
}
.full-photo-download-button:hover {
  opacity: 0.9;
}
.full-photo-download-button:active,
.full-photo-download-button:focus,
.full-photo-download-button:hover {
  border-color: black !important;
  color: inherit;
}
.invoice-upload-contained-full-photo-download-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: absolute;
  right: 90px;
  top: 10px;
}
.invoice-upload-contained-full-photo-download-button:hover {
  opacity: 0.9;
}
.invoice-upload-contained-full-photo-download-button:active,
.invoice-upload-contained-full-photo-download-button:focus,
.invoice-upload-contained-full-photo-download-button:hover {
  border-color: black !important;
  color: inherit;
}
.completed-form-contained-full-photo-download-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: absolute;
  top: 10px;
}
.completed-form-contained-full-photo-download-button:hover {
  opacity: 0.9;
}
.completed-form-contained-full-photo-download-button:active,
.completed-form-contained-full-photo-download-button:focus,
.completed-form-contained-full-photo-download-button:hover {
  border-color: black !important;
  color: inherit;
}
.full-photo-info-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  position: fixed;
  right: 150px;
  top: 50px;
}
.full-photo-info-button:hover {
  opacity: 0.9;
}
.full-photo-info-button:active,
.full-photo-info-button:focus,
.full-photo-info-button:hover {
  border-color: black !important;
  color: inherit;
}
.drawer-photo {
  margin-top: 10px;
  object-fit: contain;
  cursor: pointer;
}
.unknown-file-prompt {
  width: 400px;
  height: 175px;
  background-color: white;
  border-radius: 8px;
}
.unknown-file-prompt-title {
  font-size: 16px;
  font-family: roboto-regular;
  width: 100%;
  text-align: center;
}
.unknown-file-prompt-subtext {
  width: 100%;
  text-align: center;
}
.file-upload-preview {
  margin-top: 20px;
  max-height: 175px;
  overflow-y: scroll;
  border-radius: 4px;
  border: lightgray 1px solid;
  background-color: rgba(200, 200, 200, 0.2);
}
.file-table-row {
  cursor: pointer;
}
.file-table-row:hover {
  background-color: rgba(230, 230, 230, 0.2);
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background-color: #BF381D;
}
.files-file-reel-container {
  position: absolute;
  bottom: 50px;
  left: 20px;
  right: 20px;
}
.file-list-container {
  border: lightgray 0.25px solid;
  border-radius: 4px;
  overflow: hidden;
}
.file-download {
  position: absolute;
  top: 0px;
  right: 56px;
}
.file-download-button {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.file-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-top: lightgray 1px solid;
  margin: 20px 20px 0px 20px;
  margin-left: 20px !important;
  margin-right: 20px !important;
  font-size: 12px;
  line-height: 12px;
}
.pdf {
  border: none;
  border-radius: 8px;
}
.pdf-container {
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  justify-content: right;
  align-items: center;
  display: flex;
  overflow: hidden;
}
.basic-pdf {
  border: none;
  border-radius: 8px;
  margin: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  justify-content: center;
  padding-top: 150px;
  align-items: center;
  display: flex;
  overflow: scroll;
}
.pdf-designer-container-display .pdf-container,
.pdf-designer-container .pdf-container {
  overflow: unset;
  padding-top: 0;
}
.pdf-designer-container-display .pdf-container #pdf-wrapper,
.pdf-designer-container .pdf-container #pdf-wrapper {
  transform-origin: center !important;
}
.basic-pdf-container {
  border: none;
  border-radius: 8px;
  margin: 25px;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  justify-content: center;
  padding-top: 150px;
  overflow: scroll;
  align-items: center;
  display: flex;
}
.invoice-upload-contained-pdf-container {
  border: none;
  border-radius: 8px;
  margin: 25px;
  padding-top: 25px;
  padding-left: 30px;
  scroll-margin: 200px;
}
.basic-invoice-upload-contained-pdf-container {
  border: none;
  border-radius: 8px;
  margin: 25px;
  padding-top: 25px;
  padding-left: 30px;
  scroll-margin: 200px;
  overflow: 'scroll';
}
.completed-form-contained-pdf-container {
  border: none;
  border-radius: 8px;
  height: calc(90vh - 50px);
  scroll-margin: 200px;
  background-color: #f0f2f5;
  padding-bottom: 50px;
}
.basic-completed-form-contained-pdf-container {
  border: none;
  border-radius: 8px;
  height: calc(90vh - 50px);
  scroll-margin: 200px;
  background-color: #f0f2f5;
  padding-bottom: 50px;
  overflow: 'scroll';
}
.basic-completed-form-contained-pdf-container {
  border: none;
  border-radius: 8px;
  height: calc(90vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-margin: 200px;
  padding-top: 200px;
  background-color: #f0f2f5;
  width: 100%;
  overflow: scroll;
}
.pdf-controls {
  position: absolute;
}
.pdf-controls-container {
  position: absolute;
  left: 40px;
  top: 80px;
  bottom: 40px;
  width: 60px;
}
.invoice-upload-contained-pdf-controls-container {
  position: absolute;
  top: 45px;
  left: 0px;
}
.completed-form-contained-pdf-controls-container {
  position: absolute;
  top: 45px;
  left: 10px;
}
.pdf-control-button {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
}
.pdf-control-button:hover {
  opacity: 0.9;
}
.pdf-control-button:active,
.pdf-control-button:focus,
.pdf-control-button:hover {
  border-color: black !important;
  color: inherit;
}
.pdf-control-button-disabled {
  color: black;
  background-color: white;
  font-size: 18px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0;
  pointer-events: none;
}
.pdf-control-button-disabled:hover {
  opacity: 0.9;
}
.pdf-control-button-disabled:active,
.pdf-control-button-disabled:focus,
.pdf-control-button-disabled:hover {
  border-color: black !important;
  color: inherit;
}
.pdf-page-number {
  height: 40px;
  width: 40px;
  margin: 20px 0px;
}
.pdf-page-number-suffix {
  position: absolute;
  top: 72px;
  right: -32px;
  height: 14px;
}
.pdf-page-number-suffix-dark {
  position: absolute;
  top: 72px;
  right: -32px;
  height: 14px;
  color: white;
}
.pdf-page-number-suffix-light {
  position: absolute;
  top: 72px;
  right: -32px;
  height: 14px;
  color: black;
}
.pdf-page {
  height: 85vh;
  overflow: scroll;
  max-width: 75vw;
}
.pdf-zoom-controls-container {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
}
.invoice-upload-contained-pdf-zoom-controls-container,
.completed-form-contained-pdf-zoom-controls-container {
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
}
.pdf-zoom-controls {
  width: auto;
}
#pdf-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
}
#pdf-spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.pdf-designer-container-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 52px;
  background-color: #f0f2f5;
  padding: 20px;
}
.pdf-designer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 52px;
  background-color: #f0f2f5;
  padding: 20px;
  top: 207px;
}
.pdf-designer-container-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 52px;
  background-color: #f0f2f5;
  padding: 20px;
  top: 135px;
}
#pdf-designer-canvas {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  background-color: white;
  position: relative;
  overflow: hidden;
  margin: 20px;
}
.pdf-designer-text-base {
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  padding: 0px 5px;
  width: fit-content;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
}
.pdf-designer-text-display {
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  padding: 0px 5px;
  width: fit-content;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
}
.pdf-designer-text {
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  padding: 0px 5px;
  width: fit-content;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
}
.pdf-designer-text:hover {
  border: 1px lightgray dashed;
  cursor: grab;
  user-select: none;
}
.pdf-designer-text-edit {
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  padding: 0px 5px;
  width: fit-content;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
  outline: 1px lightgray dashed;
}
.pdf-designer-text-edit:hover {
  cursor: grab;
  user-select: none;
}
.pdf-designer-text-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  resize: none;
  padding: 0px;
  white-space: pre-wrap;
}
.pdf-designer-edit-stop {
  position: absolute;
  inset: 0px;
  background-color: transparent;
}
.pdf-designer-svg {
  position: absolute;
  inset: 0px;
  overflow: visible !important;
}
.pdf-designer-text-align {
  margin-left: 0px;
}
.pdf-designer-text-align-unselected {
  margin-left: 0px;
  color: lightgray;
}
.pdf-designer-text-align-selected {
  margin-left: 0px;
  color: black;
}
.pdf-designer-color-preview {
  position: absolute !important;
  right: 0px;
  width: 22px;
  height: 22px;
  border: 1px black solid;
  border-radius: 3px;
}
.pdf-designer-drawer-container {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 24px;
  right: 24px;
  overflow-y: auto;
}
.pdf-designer-drawer-delete {
  position: absolute;
  top: 12px;
  right: 60px;
}
.pdf-designer-toolbar-container {
  position: absolute;
  top: 157px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: #f0f2f5;
  height: 50px;
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
}
.pdf-designer-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.pdf-designer-table {
  height: 100%;
  margin: 0 -5px;
  table-layout: fixed;
}
.pdf-designer-table-item {
  position: relative;
  padding: 0px 7px;
  word-wrap: break-word;
}
.pdf-designer-table-header {
  position: relative;
  padding: 0px 7px;
  word-wrap: break-word;
}
.pdf-designer-table-header:hover {
  border-right: 1px solid black !important;
}
.pdf-designer-table-column-resizer {
  position: absolute;
  right: 0px;
  top: 20%;
  bottom: 20%;
  width: 30px;
  background-color: transparent;
  transform: translateX(15px);
  z-index: 2;
}
.pdf-designer-table-column-resizer:hover {
  cursor: col-resize;
}
.pdf-designer-image-configure-container {
  width: 100%;
  height: 200px;
}
.pdf-designer-table-text {
  overflow: hidden;
  max-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pdf-wrapper .pdf-text-annotation {
  font-family: 'courier', sans-serif;
  z-index: 100;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;
  background: none;
  overflow: ellipsis;
  transform-origin: top left;
  background: transparent;
}
.pdf-wrapper .pdf-text-annotation input {
  background: transparent;
}
.excel-container {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  margin: 25px;
  border: none;
  border-radius: 8px;
}
.excel-header {
  background-color: lightgray;
}
.excel-header:hover {
  opacity: 0.75;
  background-color: lightgray;
}
.excel-data {
  padding: 5px;
  min-width: 20px;
  width: auto;
  white-space: nowrap;
  height: 20px;
  border: lightgray 1px solid;
}
.excel-data-header {
  padding: 5px;
  min-width: 20px;
  width: auto;
  white-space: nowrap;
  height: 20px;
  border: lightgray 1px solid;
  border: gray 1px solid;
}
.excel-footer {
  border-top: black 1px solid;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #EEEE;
  height: 40px;
}
.excel-sheet-selector {
  overflow-x: scroll;
  height: 100%;
  padding: 0px 20px;
}
.excel-sheet-tab {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: darkgray 1px solid;
  border-bottom: none;
  height: 60%;
  padding: 0px 10px !important;
  margin: 0px 10px;
  background-color: white;
  cursor: pointer;
}
.excel-sheet-tab:hover {
  opacity: 0.75;
}
.excel-sheet-selected-tab {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: darkgray 1px solid;
  border-bottom: none;
  height: 60%;
  padding: 0px 10px !important;
  margin: 0px 10px;
  background-color: white;
  cursor: pointer;
  height: 80%;
  padding: 5px 10px !important;
  font-weight: bold;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.excel-sheet-selected-tab:hover {
  opacity: 0.75;
}
.form-content-container {
  position: absolute;
  top: 101px;
  left: 14px;
  right: 14px;
  bottom: 53px;
}
.form-content-container-editable {
  position: absolute;
  top: 101px;
  left: 14px;
  right: 14px;
  bottom: 53px;
  top: 127px;
}
.form-required-field {
  align-items: center;
}
.form-required-field::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  content: '*';
}
.form-field-type-title {
  font-size: 15px;
  font-family: 'roboto-medium', sans-serif;
  width: 100%;
}
.form-field-type-description {
  font-size: 12px;
  word-break: break-word;
  width: calc(100% - 5px);
  white-space: normal;
  text-overflow: ellipsis;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.form-field-type-premium {
  font-size: 12px;
  word-break: break-word;
  width: calc(100% - 5px);
  white-space: normal;
  text-overflow: ellipsis;
  padding-bottom: 10px;
}
.form-field-header-background {
  margin: -20px -15px 0px -24px;
  background-color: white;
  padding: 20px 15px 10px 24px;
}
.form-field-configure-header {
  border-bottom: 1px solid #f0f0f0;
  font-family: 'roboto-medium', sans-serif;
  padding: 10px 15px 10px 24px;
  margin: 10px -15px 0px -24px;
  background-color: white;
}
.form-field-configure-background {
  margin: 0px -15px 0px -24px;
  background-color: white;
  padding: 15px 15px 15px 24px;
}
.form-field-preview-header {
  margin: 0px -15px 0px -24px;
  padding: 10px 15px 10px 24px;
  font-family: 'roboto-medium', sans-serif;
  border-top: 1px darkgray solid;
}
.form-field-preview-background {
  margin: 0px -15px 0px -24px;
  background-color: #f0f2f5;
  padding: 15px 15px 15px 24px;
}
.form-field-preview {
  border-radius: 4px;
  background-color: white;
  border: 1px lightgray solid;
  padding: 10px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 90vw;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: black;
}
.form-attachment-add-button {
  width: 48px;
  height: 48px;
  border: 1px lightgray dashed;
  box-shadow: none;
  padding: 0px;
  border-radius: 4px;
}
.form-attachment-add-button:active,
.form-attachment-add-button:focus,
.form-attachment-add-button:hover {
  border-color: #BF381D !important;
  color: #BF381D !important;
}
.form-attachment-add-button:hover {
  opacity: 0.65;
}
.form-attachment-add-button:disabled {
  background-color: inherit;
}
.form-attachment-add-button:disabled:hover {
  opacity: 0.5;
}
.form-field-container {
  overflow: scroll;
  padding: 10px 20px;
  max-height: 100%;
}
.form-field-container-po {
  overflow: scroll;
  padding: 10px 20px;
  max-height: 100%;
  max-height: 92%;
}
.form-field-container-po-edit {
  overflow: scroll;
  padding: 10px 20px;
  max-height: 100%;
  max-height: 85%;
}
.form-field-container-border {
  overflow: scroll;
  padding: 10px 20px;
  max-height: 100%;
  border-top: 1px #f0f0f0 solid;
}
.form-drawer-container {
  position: absolute;
  top: 65px;
  left: 14px;
  right: 14px;
  bottom: 52px;
}
.form-preview-shadow {
  box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
  -webkit-box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
  -moz-box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
}
.form-export-preview {
  background-color: #f0f2f5;
  position: absolute;
  left: -14px;
  right: -14px;
  top: 48px;
  bottom: 0px;
  box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
  -webkit-box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
  -moz-box-shadow: inset 0px 0px 7px 2px rgba(35, 35, 35, 0.2);
}
.form-detail-nux-view {
  z-index: 1000;
  position: relative;
  background-color: white;
  margin: -20px;
  padding: 20px;
  pointer-events: none;
}
#form-approval-button {
  background-color: darkgreen;
  border-color: darkgreen;
}
#form-approval-button:hover {
  opacity: 0.85;
}
.form-timeline-container {
  position: absolute;
  inset: 0px;
  top: 101px;
  padding: 20px;
  overflow-y: scroll;
}
.ot-timeline-item-head {
  width: 5px;
  height: 5px;
}
.ot-timeline-item-content,
.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0px 0px 0px 26px;
  word-break: break-word;
}
.ot-timeline-cancel-item-head {
  width: 5px;
  height: 5px;
  color: #BF381D !important;
  font-weight: 400px;
}
.ot-timeline-cancel-item-content {
  position: relative;
  top: -6px;
  margin: 0px 0px 0px 26px;
  word-break: break-word;
  color: #BF381D !important;
  font-weight: 400px;
}
.form-map-container {
  position: absolute;
  top: 200px;
  bottom: 50px;
  left: 424px;
  right: 24px;
}
.form-signature-container {
  max-height: 239;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  overflow: hidden;
  background-color: rgba(198, 198, 198, 0.15);
}
.form-signature-header {
  height: 39px;
  margin-bottom: 10px;
  width: 100%;
  background-color: white;
  padding: 5px 50px;
  border-bottom: 1px solid lightgray;
  font-family: roboto-medium, sans-serif;
  font-size: 18px;
}
.form-signature-image-container {
  border-radius: 4px;
  background-color: white;
  border: 1px lightgray solid;
  padding: 10px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 89px;
  margin: 10px;
}
.form-signature-image {
  max-width: 500px;
  max-height: 89px;
  margin-top: 0px;
}
.form-export-file-location-scroll {
  width: 100%;
  padding: 20px 24px;
  bottom: 53px;
  top: 55px;
  left: 0px;
  right: 0px;
  position: absolute;
  overflow-y: auto;
}
.form-add-container {
  overflow-y: scroll;
  padding: 20px 15px 53px 24px;
  width: 100%;
  min-width: 400px;
  height: 100%;
}
.form-response-container {
  overflow-y: auto;
  position: absolute;
  top: 55px;
  left: 24px;
  right: 24px;
  bottom: 53px;
  padding: 10px 0px;
}
.form-calculation-container {
  position: relative;
  margin: 5px 0px;
}
.form-calculation-text-field {
  border-radius: 6px;
  min-height: 34px;
  background-color: transparent;
  line-height: 23px !important;
  color: transparent;
  caret-color: black;
  border: 1px lightgray solid;
  margin-bottom: 4px;
}
.form-calculation-text {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  line-height: 23px;
  pointer-events: none;
  background-color: white;
  overflow: hidden;
  min-height: 32px;
  padding: 4px 11px 4px 15px;
  overflow-wrap: break-word;
  border-radius: 6px;
  white-space: pre-wrap;
  border: 1px transparent solid;
  margin-bottom: 4px;
}
.form-calculation-field-picker {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  max-height: 250px;
}
.form-calculation-subtext {
  margin-top: 20px;
}
.form-calculation-menu {
  padding: 10px 0px;
}
#form-calculation-error {
  height: 32px;
  width: 100%;
  color: red;
}
#labour-add-drawer-scroll {
  overflow-y: auto;
  position: absolute;
  top: 55px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 20px 0px;
}
.labour-add-task-row {
  margin: 10px 0px;
}
.labour-add-drawer-task {
  margin: 10px 0px;
  border-radius: 8px;
  width: 200px;
}
.labour-add-drawer-task:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.form-default-data-row {
  height: 25px;
  width: 400px;
}
.form-saved-table-row {
  vertical-align: top;
}
#form-response-pdf-preview-button {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.form-user-selector-filter {
  position: relative;
  display: inline;
}
.table-add-scroll {
  overflow-y: auto;
  position: absolute;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 24px;
  right: 24px;
  bottom: 53px;
  top: 111px;
}
.form-signature-canvas {
  border: 1px lightgray solid;
  border-radius: 6px;
}
#form-signature-clear-button {
  position: absolute;
  top: 70px;
  right: 40px;
  width: 80px !important;
  background-color: #F8F8F8;
  color: red;
}
#form-signature-import-button {
  position: absolute;
  top: 70px;
  left: 40px;
  width: 80px !important;
  background-color: #F8F8F8;
  color: black;
}
#form-signature-save-button {
  position: absolute;
  right: 40px;
  width: 80px !important;
  background-color: #F8F8F8;
  color: black;
}
#form-response-draft-clear-button {
  position: absolute;
  left: 130px;
  bottom: 10px;
}
#form-response-draft-save-button {
  position: absolute;
  right: 182px;
  bottom: 10px;
}
.form-response-draft-timestamp {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  width: 300px;
  text-align: center;
}
.form-link-title {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
}
.form-link-title:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.material-add-drawer-breadcrumb {
  font-size: 14px;
  color: gray;
  padding: 3px;
  border-radius: 4px;
}
.material-add-drawer-breadcrumb-clickable {
  font-size: 14px;
  color: gray;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
}
.material-add-drawer-breadcrumb-clickable:hover {
  background-color: lightgray;
  opacity: 0.65;
  color: black;
}
.form-template-global-import {
  position: absolute;
  top: 10px;
  left: 200px;
}
.form-multi-sig-image {
  max-height: 50px;
  object-fit: contain;
}
.form-multi-sig-image:hover {
  cursor: pointer;
}
.form-item-more-button {
  position: absolute;
  right: 3px;
  top: 0px;
}
.form-loading-container {
  position: absolute;
  inset: 0px;
  pointer-events: none;
}
.virtual-table-cell-base {
  box-sizing: border-box;
  padding: 5px;
  background: #FFF;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.virtual-table-cell-base:hover {
  cursor: pointer;
}
.virtual-table-cell {
  box-sizing: border-box;
  padding: 5px;
  background: #FFF;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
}
.virtual-table-cell:hover {
  cursor: pointer;
}
.virtual-report-table-cell {
  box-sizing: border-box;
  padding: 5px;
  background: #FFF;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.virtual-report-table-cell:hover {
  cursor: pointer;
}
.virtual-table-cell-inner {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 100%;
  overflow: hidden;
}
.form-status-tag {
  align-self: center;
  width: fit-content;
}
.form-shift-container {
  border: 1px solid lightgray;
  border-radius: 4px;
  overflow: hidden;
}
.form-shift-row:hover {
  cursor: pointer;
  opacity: 0.85;
}
.manual-forms-header .ant-radio-group {
  border-radius: 10px;
}
.manual-forms-header .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background: #BF381D;
  border-color: #BF381D;
}
.manual-forms-header .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #BF381D;
}
.manual-forms-header .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #BF381D;
}
.manual-forms-header .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
  background: #BF381D;
  border-color: #BF381D;
}
.manual-forms-header .ant-radio-button-wrapper:hover {
  color: #BF381D;
}
.form-detail-color-picker {
  margin-bottom: 0;
  width: 350px;
}
.login-container .form-response-container {
  background: white;
  height: fit-content;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  border-radius: 25px;
  position: unset;
  margin-top: 55px;
}
.login-container .form-response-container .external-form-title {
  font-size: 26px;
  font-weight: 650;
}
.login-container .drawer-footer {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.form-tab-pane {
  height: 90vh;
  width: 100%;
}
.form-import-container {
  width: 95%;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  height: 100%;
  overflow-y: auto;
}
.form-workflow-node {
  width: 100%;
  margin-top: 20px;
}
.form-before {
  background: #ff000047;
  padding: 5px;
  margin-right: 5px;
}
.form-after {
  background: #a6f0a6bf;
  padding: 5px;
  margin-left: 5px;
}
.form-diff-container {
  border: 1px solid #cccccc;
  margin-top: 10px;
  padding: 5px;
  background: #f6f6ed;
}
.variable-input-container {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px lightgray solid;
  overflow-y: auto;
  background-color: transparent;
}
.variable-input-container:hover,
.variable-input-container:active,
.variable-input-container:focus {
  border: 1px black solid;
}
.variable-input-input {
  width: 100%;
  border: none;
  background-color: transparent;
  color: transparent;
  caret-color: black;
  outline: none;
  line-height: 22px;
  min-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 6px;
}
.variable-input-renderer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  line-height: 22px;
  pointer-events: none;
  background-color: white;
  align-self: baseline;
  overflow: hidden;
  min-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-wrap: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 6px;
  word-wrap: break-word;
  /* This line preserves trailing
      whitespaces */
  white-space: break-spaces;
  user-select: none;
  scrollbar-width: none;
}
.transposed-table th {
  display: none;
}
.transposed-table td:first-child {
  background-color: #fafafa;
}
.workflow-add-step-button {
  background-color: white;
  width: 30px;
  height: 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px darkgray solid;
  border-top: 1px darkgray solid;
  border-bottom: 1px darkgray solid;
  position: absolute;
  left: 0px;
  top: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 10000;
}
.workflow-add-text {
  position: absolute;
  top: 25px;
  right: 50px;
  width: 100px;
}
.workflow-drawer {
  background-color: white;
  width: 270px;
  height: 100%;
  margin-left: 30px;
  border-left: 1px lightgray solid;
}
.workflow-drawer .ant-tabs-content {
  height: calc(100% - 21px);
  overflow-y: scroll;
}
.workflow-drawer-scroll {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
}
.workflow-drawer-tabs {
  height: 100%;
}
.workflow-node {
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.workflow-complete-node {
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border: 1px darkred solid;
  background-color: #BF381D;
  color: white;
  text-align: center;
  font-weight: bold;
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.workflow-trigger-node {
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border: 1px darkgray solid;
  color: white;
  background-color: #323232;
}
.workflow-action-node {
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border: 1px darkgray solid;
  background-color: white;
  width: 200px;
}
.workflow-logic-node {
  background-color: #FFDC73;
}
.workflow-user-node {
  height: 70px;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  background-color: #F2BD1E;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.workflow-action-delete-button {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  background-color: #BF381D;
  border: 2px white solid;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.workflow-action-delete-button:hover {
  transform: scale(1.1);
}
.workflow-pdf-preview-icon:hover {
  opacity: 0.65;
}
.workflow-handle {
  background: #555 !important;
  font-size: 10px;
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.workflow-handle-bottom {
  background: #555 !important;
  font-size: 10px;
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  bottom: -8px !important;
}
.workflow-handle-top {
  background: #555 !important;
  font-size: 10px;
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: -8px !important;
}
.workflow-email-icon {
  cursor: pointer;
  font-size: 10px;
}
.text-overflow-ellipsis {
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}
.workflow-email-tag-label {
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 117px;
  vertical-align: top;
}
.form-trigger-subtext {
  font-family: 'roboto-light', sans-serif;
  font-size: 12px;
}
.form-trigger-label-title {
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
}
.form-trigger-help {
  max-width: 150px;
}
#email-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFDC73;
  align-content: center;
}
#email-logo-container {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  border-radius: 6px;
}
.email-page-header {
  position: absolute;
  top: 130px;
  width: 400px;
}
#email-header {
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: black;
  padding: 200px 5px;
  z-index: 100;
  pointer-events: none;
}
#email-unsub-text {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 60px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  color: dimgray;
  transition: font-size 0.5s;
}
#email-unsub-text:hover {
  opacity: 0.9;
  font-size: 14.5px;
}
#email-sub-button {
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
#email-loading {
  width: 100%;
  height: 100%;
  pointer-events: none;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
#menu-version-container {
  width: 100%;
  position: absolute;
  height: 120px;
  bottom: 0px;
  background-color: #323232;
}
#menu-version-divider-container {
  width: 100%;
  padding: 0px 15px;
}
#menu-version {
  width: 100%;
  color: white;
  text-align: center;
  font-family: roboto-regular, sans-serif;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 20px;
}
#app-menu-scroll {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 10px;
}
#app-menu-container {
  flex: 1 1 0;
  padding-bottom: 120px;
}
#divisions-dropdown-container {
  width: 100%;
  min-height: 20px;
  padding-bottom: 10px;
  border-top: 1px rgba(198, 198, 198, 0.4) solid;
}
#divisions-header-container {
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  padding: 5px 0px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  user-select: none;
}
#divisions-header-title {
  display: inline-block;
  width: fit-content;
}
#divisions-all-button {
  display: inline-block;
  width: fit-content;
  padding: 0px 2px;
  border-radius: 4px;
  cursor: pointer;
}
#divisions-all-button:hover {
  background-color: #BF381D;
}
.divisions-dropdown {
  width: 100%;
  overflow: hidden;
}
.divisions-label-container {
  padding: 3px 4px;
  line-height: 22px;
  overflow: hidden;
  position: relative;
}
.division-label-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
}
.division-item {
  display: inline-block;
  position: relative;
  color: white;
  background-color: #BF381D;
  border-color: #BF381D;
  border-radius: 4px;
  max-width: 160px;
}
.division-tag {
  display: inline-block;
  position: relative;
  color: white;
  background-color: #BF381D;
  border-color: #BF381D;
  border-radius: 4px;
  max-width: 160px;
}
.division-tag-label {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}
.division-tag-label-closeable {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  max-width: 100px;
}
.division-tag-label-uncloseable {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.divisions-label {
  display: inline-block;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  position: relative;
  color: white;
  background-color: #BF381D;
  border-color: #BF381D;
  padding: 0px 8px 0px 8px;
  border-radius: 4px;
  height: 22px;
  font-size: 12px;
}
.divisions-label-many {
  display: inline-block;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  position: relative;
  color: white;
  background-color: #BF381D;
  border-color: #BF381D;
  padding: 0px 8px 0px 8px;
  border-radius: 4px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
}
#schedule-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
#schedule-header-container {
  width: 100%;
  height: 50px;
}
#schedule-header-container .switch-container {
  display: flex;
  align-items: center;
}
#schedule-header-container .switch-container button {
  margin-left: 8px;
}
.schedule-date-type-picker {
  width: 90px;
}
.schedule-date-picker {
  width: 200px;
}
.schedule-user-select {
  width: 100%;
  background-color: transparent;
  border: none;
}
.schedule-user-tag {
  pointer-events: none;
}
.schedule-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin-top: 15px;
  border: 1px lightgray solid;
  border-radius: 6px;
  overflow-y: auto;
  background-color: white;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.schedule-view-container::-webkit-scrollbar {
  display: none;
}
.schedule-view-inner-container {
  position: relative;
  padding: 10px 0px;
  width: 100%;
}
.schedule-view-inner-container-right-divider {
  position: relative;
  padding: 10px 0px;
  width: 100%;
  border-right: 1px lightgray solid;
}
.schedule-hour {
  width: 100%;
  position: relative;
}
.schedule-hour-hidden {
  width: 100%;
  position: relative;
  visibility: hidden;
}
.schedule-divider {
  margin-left: 60px;
  margin-right: 20px;
  border-top: 1px lightgray solid;
}
.schedule-hour-text {
  width: 50px;
  font-size: 12px;
  color: darkgray;
  transform: translateY(-10px) translateX(-5px);
  text-align: right;
  pointer-events: none;
  user-select: none;
}
.schedule-item {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  background-color: #BF381D;
  max-width: calc(100% - 80px);
  position: absolute;
  left: 60px;
  right: 20px;
  overflow: hidden;
}
.schedule-item-micro {
  font-size: 6px;
  border-radius: 2px;
  line-height: 6px;
}
.schedule-item-small {
  line-height: 10px;
  font-size: 10px;
  border-radius: 4px;
  padding: 3px 0px;
}
.schedule-item-regular {
  line-height: 12px;
  font-size: 12px;
  border-radius: 6px;
  padding: 5px 0px;
}
.schedule-item-large {
  line-height: 14px;
  font-size: 14px;
  border-radius: 8px;
  padding: 7px 0px;
}
.schedule-item-week {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  background-color: #BF381D;
  max-width: calc(100% - 80px);
  position: absolute;
  left: 60px;
  right: 20px;
  overflow: hidden;
  left: 0px;
  right: 0px;
  max-width: calc(100% - 15px);
  transform: translateY(-10px);
}
.schedule-item-text {
  color: white;
  padding-left: 10px;
}
.schedule-form-item {
  margin-bottom: 5px;
}
.schedule-form-reminder {
  display: flex;
  align-items: center;
}
.schedule-form-reminder-item {
  margin-bottom: 5px;
  margin-right: 4;
}
.schedule-item-anchor {
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: ns-resize;
  height: 20px;
}
.schedule-item-top-anchor {
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: ns-resize;
  height: 20px;
  top: 0px;
}
.schedule-item-bottom-anchor {
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: ns-resize;
  height: 20px;
  bottom: 0px;
}
.schedule-item-user-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  width: fit-content;
}
.schedule-item-user-circle {
  margin-right: 10px;
}
.schedule-item-user-popover-container {
  margin-left: -20px;
}
.schedule-item-user-popover-divider {
  border-bottom: 1px lightgray solid;
  margin-left: 20px;
  padding: 5px;
  width: calc(100% - 30px);
  margin-bottom: 10px;
}
.schedule-week-header-container {
  position: relative;
  min-height: 50px;
  padding-left: 60px;
  width: 100%;
  border-bottom: 1px lightgray solid;
  background-color: #fafafa;
}
.schedule-week-day-header {
  position: relative;
  width: 100%;
  min-height: 75px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer;
}
.schedule-week-day-header:hover {
  opacity: 0.65;
}
.schedule-week-day-header-border {
  position: absolute;
  bottom: 0px;
  top: 25px;
}
.schedule-week-day-header-right-border {
  position: absolute;
  bottom: 0px;
  top: 25px;
  right: 0px;
  border-right: 1px lightgray solid;
}
.schedule-week-day-header-left-border {
  position: absolute;
  bottom: 0px;
  top: 25px;
  left: 0px;
  border-left: 1px lightgray solid;
}
.schedule-week-day-column {
  flex: 1;
  overflow-x: hidden;
}
.schedule-week-container {
  position: absolute;
  inset: 0px;
  left: 60px;
  display: flex;
}
.schedule-week-grid {
  position: relative;
}
.schedule-left-vertical-divider {
  position: absolute;
  left: 60px;
  top: -30px;
  bottom: 0px;
  border-left: 1px lightgray solid;
}
.all-day-week-header {
  width: calc(100% - 60px);
  height: 25px;
  position: absolute;
  bottom: 0px;
}
.all-day-header {
  left: 1px;
  right: 1px;
  height: 25px;
  padding: 0px 5px;
  position: absolute;
  background-color: white;
  z-index: 100;
  border-bottom: 1px lightgray solid;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
}
.all-day-header:hover {
  border-bottom: 1px darkgray solid;
}
.all-day-header-text {
  width: 50px;
  font-size: 12px;
  color: darkgray;
  transform: translateY(-10px) translateX(-5px);
  text-align: right;
  pointer-events: none;
  user-select: none;
  padding-left: 5px;
  transform: translateY(5px) translateX(-5px);
}
.all-day-header-container {
  position: absolute;
  top: 0px;
  margin-left: 60px;
  width: calc(100% - 50px);
  height: 100%;
}
.schedule-month-header-container {
  position: relative;
  min-height: 50px;
  padding-left: 60px;
  width: 100%;
  border-bottom: 1px lightgray solid;
  background-color: #fafafa;
  height: 50px;
  padding-left: 0px;
  padding-bottom: 10px;
}
.schedule-month-day-header {
  position: relative;
  width: 100%;
  min-height: 75px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer;
  height: 25px;
  min-height: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: auto;
  pointer-events: none;
  background-color: inherit;
  border-bottom: none;
}
.schedule-month-day-header:hover {
  opacity: 0.65;
}
.schedule-month-day-header-border-left {
  position: relative;
  width: 100%;
  min-height: 75px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer;
  height: 25px;
  min-height: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: auto;
  pointer-events: none;
  background-color: inherit;
  border-bottom: none;
  border-left: 1px lightgray solid;
}
.schedule-month-day-header-border-left:hover {
  opacity: 0.65;
}
.schedule-month-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin-top: 15px;
  border: 1px lightgray solid;
  border-radius: 6px;
  overflow-y: auto;
  background-color: white;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  position: relative;
}
.schedule-month-week {
  position: relative;
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
  max-height: 25%;
}
.schedule-month-week-border-bottom {
  position: relative;
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
  max-height: 25%;
  border-bottom: 1px lightgray solid;
}
.schedule-month-day {
  width: calc(100% / 7);
  position: relative;
  padding-top: 30px;
  height: 100%;
  z-index: 1;
}
.schedule-month-grid-container {
  position: absolute;
  inset: 0px;
  display: flex;
  justify-content: space-evenly;
  pointer-events: none;
}
.schedule-month-grid-line {
  height: 100%;
  border-left: 1px lightgray solid;
}
.schedule-month-day-text {
  position: absolute;
  top: 3px;
  left: 3px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  overflow: hidden;
}
.schedule-month-day-text:hover {
  background-color: rgba(200, 200, 200, 0.25);
}
.schedule-month-day-text-faded {
  position: absolute;
  top: 3px;
  left: 3px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  overflow: hidden;
  opacity: 0.45;
}
.schedule-month-day-text-faded:hover {
  background-color: rgba(200, 200, 200, 0.25);
}
.schedule-month-entry-container {
  flex: 1;
}
.schedule-monthly-entry-text {
  font-size: 10px;
  line-height: 12px;
}
.schedule-month-day-entry {
  font-size: 10px;
  line-height: 12px;
  width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  height: 16px;
  padding: 1px 2px;
  margin: 2px 4px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.schedule-month-day-entry:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-day-entry-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 3px;
}
.schedule-month-day-entry-dot-mine {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 3px;
  background-color: #BF381D;
}
.schedule-month-day-entry-dot-other {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 3px;
  background-color: #5C5C56;
}
.schedule-month-multiday-entry {
  font-size: 10px;
  line-height: 12px;
  width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  height: 16px;
  padding: 1px 2px;
  margin: 2px 4px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #5C5C56;
  width: 100%;
  z-index: 5;
  color: white;
  max-width: unset;
}
.schedule-month-multiday-entry:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-multiday-entry-hover {
  font-size: 10px;
  line-height: 12px;
  width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  height: 16px;
  padding: 1px 2px;
  margin: 2px 4px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #5C5C56;
  width: 100%;
  z-index: 5;
  color: white;
  max-width: unset;
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-multiday-entry-hover:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-multiday-flat-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0px;
}
.schedule-month-multiday-flat-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0px;
}
.schedule-month-multiday-placeholder {
  height: calc(16px + (2px * 2));
  cursor: pointer;
  z-index: 0;
}
.schedule-month-more {
  font-size: 10px;
  line-height: 12px;
  width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  height: 16px;
  padding: 1px 2px;
  margin: 2px 4px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.schedule-month-more:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-more:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.schedule-month-more-container {
  max-height: 200px;
  width: 150px;
  overflow-y: auto;
}
.schedule-month-more-close {
  cursor: pointer;
}
.schedule-month-more-close:hover {
  opacity: 0.65;
}
.schedule-biweekly-day-text {
  position: relative;
  width: 100%;
  min-height: 75px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 10px;
  min-height: 10px;
  width: fit-content;
  border-radius: 12px;
  background-color: inherit;
}
.schedule-biweekly-day-text:hover {
  opacity: 0.65;
}
.schedule-biweekly-day-text:hover {
  background-color: rgba(200, 200, 200, 0.25);
  cursor: pointer;
}
.whose-scheduled-row {
  cursor: pointer;
}
.whose-scheduled-date-row {
  background-color: rgba(250, 250, 250, 0.97);
}
.whose-scheduled-date-row:hover {
  background-color: rgba(250, 250, 250, 0.97);
}
.whose-scheduled-date {
  padding-left: 50px;
}
.whose-scheduled-search {
  padding: 10px 20px;
  border-bottom: 1px solid lightgray;
}
#schedule-container > div {
  width: 100%;
}
#schedule-container > div ._CZjuD {
  overflow-x: scroll;
}
#schedule-container > div ._19jgW {
  display: none;
}
#schedule-container > div ._nI1Xw > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#schedule-container > div .gantt-task-row:nth-child(even) {
  background: #f5f5f5;
}
#schedule-container > div .gantt-task-row:nth-child(odd) {
  background: #fff;
}
#schedule-container > div .filter-drawer-tabs .ant-tabs-tab {
  width: 220px;
}
#schedule-container > div ._3lLk3 {
  overflow: visible;
}
#schedule-container > div rect._2dZTy:hover {
  fill: #d9d9d9;
}
#schedule-container > div ._35nLX {
  fill: #F0F2F5;
}
#schedule-container > div .highlighted-row {
  background: #d9d9d9;
  fill: #d9d9d9;
}
#schedule-container > div ._WuQ0f {
  padding: 0px 10px;
}
.form-label {
  color: rgba(0, 0, 0, 0.85);
}
.gantt-task-edit:hover {
  background: #92ff90;
}
.error-warning-form-item .ant-form-item-explain {
  color: #f5ab16;
  max-width: 100%;
}
.error-warning-form-item.ant-form-item-has-error .ant-input-number {
  border-color: #f5ab16;
  max-width: 100%;
}
.error-warning-form-item.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: #f5ab16 !important;
  max-width: 100%;
}
.form-warning {
  color: #f5ab16;
}
#gantt-schedule-container {
  height: unset;
}
.load-file-container {
  border: 1px dashed gray;
}
.gantt-task-label {
  padding: 0px 10px;
  position: relative;
  height: 30px;
}
.schedule-user-base-container {
  overflow-x: auto;
  width: fit-content;
  justify-content: flex-start;
  display: flex;
}
.schedule-user-container {
  width: 100%;
  flex: 1;
  height: calc(100% - 50px);
  overflow-y: hidden;
  overflow-x: auto;
}
.schedule-user-name-header {
  overflow-x: auto;
  width: fit-content;
  justify-content: flex-start;
  display: flex;
  height: 25px;
  border-bottom: 1px solid lightgray;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.schedule-user-body {
  overflow-x: auto;
  width: fit-content;
  justify-content: flex-start;
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
}
.schedule-user-column {
  width: 100%;
  height: fit-content;
  flex: 1;
  margin-top: 20px;
  border: 1px lightgray solid;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  min-width: 250px;
}
.schedule-user-column-header {
  flex: 1;
  width: 100%;
  text-align: center;
  height: 25px;
  min-width: 250px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.schedule-user-name-header::-webkit-scrollbar {
  display: none;
}
.schedule-loading-container {
  position: absolute;
  inset: 0;
}
.gantt-schedule-list-table .ant-table-tbody {
  background: #FFF;
}
.gantt-schedule-checkbox-group .ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}
.schedule-user-weekly-view-container {
  max-height: calc(100vh - 175px);
  padding-top: 10px;
}
.schedule-user-weekly-view-container .schedule-view-container {
  flex-direction: column;
  position: relative;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.schedule-user-weekly-view-container::-webkit-scrollbar {
  display: none;
}
.schedule-user-weekly-header-container {
  position: relative;
  min-height: 50px;
  padding-left: 60px;
  width: 100%;
  border-bottom: 1px lightgray solid;
  background-color: #fafafa;
  height: 50px;
  padding-left: 0px;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-flow: row nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-bottom: none;
}
.schedule-user-weekly-header-container::-webkit-scrollbar {
  display: none;
}
.schedule-user-week-day-column {
  display: flex;
  flex: 1;
  min-width: calc(100% / 8);
  border-right: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  background-color: #fafafa;
  justify-content: center;
  flex-direction: row;
}
.schedule-user-week-row {
  flex-flow: row nowrap;
}
.schedule-user-week-row-user-name {
  position: relative;
  width: 100%;
  min-height: 75px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  cursor: pointer;
  height: 25px;
  min-height: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: auto;
  pointer-events: none;
  background-color: inherit;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fafafa;
  border-bottom: 1px lightgray solid;
  border-right: 1px lightgray solid;
  position: sticky;
  left: 0;
  z-index: 10;
  text-wrap: wrap;
  word-break: break-all;
  overflow: hidden;
}
.schedule-user-week-row-user-name:hover {
  opacity: 0.65;
}
.schedule-user-week-day-block {
  height: 100%;
  width: 100%;
  border-right: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
}
.schedule-user-week-day-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28px;
  padding: 3px;
  z-index: 5;
}
.schedule-user-week-day-header:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px lightgray;
}
#divisions-container {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
}
.divisions-card {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.division-name-row {
  padding-left: 10px;
  border-bottom: 1px solid rgba(92, 92, 86, 0.65);
}
.division-table-tag {
  border-radius: 4px;
  align-self: center;
  margin: 0px;
}
.division-inactive-tag {
  border-radius: 4px;
  align-self: center;
  margin: 0px;
  border: 1px solid #BF381D;
  background-color: white;
  color: #BF381D;
}
.division-active-tag {
  border-radius: 4px;
  align-self: center;
  margin: 0px;
  background-color: #BF381D;
  color: white;
}
#equipment-check-modal-flavour-text {
  position: relative;
  font-size: 14px;
  margin: 20px 0px 30px 0px;
}
.equipment-check-modal-input {
  width: calc(100% - 20px);
  margin: 0px 10px 50px 10px;
  border-radius: 6px;
}
.equipment-check-modal-button-container {
  margin: 0px 10px 10px 10px;
}
#equipment-slider-form-inputs {
  max-height: 350px;
}
#equipment-slider-history {
  flex: 1;
}
.equipment-slider-history-divider {
  margin: 5px 0px 0px 0px;
}
#equipment-slider-history-title {
  color: black;
  opacity: 0.85;
}
.equipment-profile-picture {
  object-fit: contain;
  object-position: top right;
  width: 200px;
  height: 200px;
}
.equipment-profile-picture:hover {
  cursor: pointer;
}
#equipment-check-modal-switch {
  position: absolute;
  right: 10px;
  top: 0px;
  width: 100px;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}
.equipment-check-modal-switch-child {
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}
.customer-billing-address-container {
  width: 100%;
  position: relative;
}
.customer-billing-address-checkbox {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 175px;
  text-align: right;
}
.customer-tab-container {
  height: 100%;
}
.customer-display-form {
  position: absolute;
  top: 55px;
  left: 24px;
  right: 24px;
  overflow-y: auto;
}
.customer-display-form-with-footer {
  position: absolute;
  top: 55px;
  left: 24px;
  right: 24px;
  overflow-y: auto;
  bottom: 53px;
}
.customer-display-form-without-footer {
  position: absolute;
  top: 55px;
  left: 24px;
  right: 24px;
  overflow-y: auto;
  bottom: 0px;
}
.customer-card-title {
  width: 100%;
  font-family: 'roboto-bold', sans-serif;
  font-size: 16px;
  color: #323232;
}
.customer-card-detail {
  width: 100%;
  font-family: 'roboto-light', sans-serif;
  font-size: 12px;
  color: #85857F;
  line-height: 15px;
  margin: 2px 0px;
}
.customer-project-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 45px;
}
.customer-project-item-divider {
  height: 1px;
  width: 100%;
  padding: 0px 5px;
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
}
.customer-project-item {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px !important;
}
.customer-project-item:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.customer-project-empty {
  height: 100%;
  width: 100%;
}
.customer-project-search-row {
  width: 100%;
  padding: 15px 0px;
  border-bottom: 1px solid lightgray;
}
.customer-project-scoller {
  height: calc(100% - 63px);
  width: 100%;
  overflow-y: auto;
}
.customer-project-select-search {
  padding: 20px;
  border-bottom: 1px solid lightgray;
}
#customer-add-project-footer-cancel,
#customer-add-project-footer-back {
  margin-right: 8px;
}
.customer-client-portal-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 45px;
}
.customer-portal-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 43px;
}
.customer-broadcast-history {
  height: calc(100vh - 337px);
  overflow-y: auto;
  border-top: 1px lightgray solid;
}
.address-book-table-row {
  cursor: pointer;
}
.address-book-table-row:hover {
  background-color: rgba(230, 230, 230, 0.2);
}
.address-book-table-row:focus {
  background-color: rgba(230, 230, 230, 0.2);
  outline: none;
  border: none;
}
.materials-table {
  width: 100%;
  height: 100%;
}
.materials-header {
  min-height: 32px;
  margin-bottom: 15px;
  overflow: hidden;
  padding-left: 22px;
}
.materials-add-form {
  position: absolute;
  top: 55px;
  bottom: 53px;
  left: 0px;
  right: 0px;
  min-width: 600px;
}
.materials-write-form {
  position: absolute;
  top: 55px;
  left: 0px;
  right: 0px;
  min-width: 600px;
  bottom: 53px;
}
.materials-readonly-form {
  position: absolute;
  top: 55px;
  bottom: 53px;
  left: 0px;
  right: 0px;
  min-width: 600px;
  bottom: 0px;
}
.materials-add-drawer-form {
  position: absolute;
  top: 55px;
  bottom: 53px;
  left: 24px;
  right: 24px;
}
#material-table-more-button {
  padding: 0px;
  margin: 0px;
  width: 40px !important;
}
#material-table-more-button:hover {
  z-index: 2;
  background-color: rgba(220, 220, 220, 0.5);
}
.materials-table-row {
  background-color: white;
}
.materials-table-row:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.materials-table-row-threshold {
  background-color: white;
  color: red;
}
.materials-table-row-threshold:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
#material-form-price {
  font-size: 14px !important;
  font-family: roboto-regular, sans-serif !important;
  margin-bottom: 0px;
}
.material-bulk-change-preview-old-value {
  text-decoration: line-through;
  color: #b9b9b9;
}
.material-bulk-change-preview-new-positive-value {
  color: green;
}
.material-bulk-change-preview-new-negative-value {
  color: red;
}
#material-location-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.material-location-header {
  margin: 10px 0px;
}
.material-location-table {
  margin: 0px -24px 50px -24px;
  max-width: calc(100% + 48px);
}
.material-table-location-header,
.material-table-location-value {
  padding-left: 20px;
  word-wrap: break-word;
  word-break: break-word;
}
.material-location-selector-option {
  display: flex;
  flex-wrap: wrap;
}
.material-tab-pane {
  padding: 0px !important;
  height: calc(100vh - 108px) !important;
  overflow-y: auto;
}
.material-tab-pane-container {
  padding: 0px 24px;
  overflow-y: auto;
}
.material-write-tab-pane-container {
  padding: 0px 24px;
  overflow-y: auto;
  max-height: calc(100vh - 108px);
}
.material-readonly-tab-pane-container {
  padding: 0px 24px;
  overflow-y: auto;
  max-height: calc(100vh - 55px);
}
.material-history-container {
  position: absolute;
  inset: 0px;
  top: 46px;
  overflow-y: auto;
  padding: 10px 24px 0px;
}
.custom-form-table-add-drawer {
  position: absolute;
  top: 55px;
  right: 0px;
  bottom: 51px;
  left: 0px;
  padding: 0px 24px;
  overflow-y: auto;
  min-width: 900px;
}
.user-add-form {
  position: absolute;
  top: 55px;
  left: 0px;
  right: 0px;
  bottom: 53px;
  padding: 0px 24px;
  overflow-y: auto;
}
#user-label-container {
  margin-top: 5px;
}
.user-add-certification-button {
  margin-bottom: 10px;
}
.user-add-certification-form-label {
  color: #000;
}
.user-expired-certification-label {
  color: red;
}
.union-select-border {
  border-bottom: lightgray 1px solid;
  width: '100%';
}
#union-local-border-top {
  width: calc(100% - 20px);
  margin: 0px 10px;
}
.union-class-name-cell {
  padding-left: 10px;
}
.union-label-row {
  margin: 10px 20px;
}
#company-image-preview img {
  border: 1px solid #5c5c56;
  border-radius: 5px;
}
#client-portal-profile-fields-select {
  width: calc(100% - 40px);
  margin: 10px;
}
.integation-auth-code {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  padding: 0px 55px 0px 20px;
}
#gmail-visibility-toggle-container {
  position: absolute;
  right: 10px;
  top: 65px;
  width: 32px;
}
.export-column {
  flex: 1;
  min-width: 300px;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin: 0px 3px;
  padding: 5px;
  background-color: white;
}
.export-column-header-scroller {
  width: calc(100% - 154px);
  overflow-x: auto;
  padding-bottom: 10px;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  scrollbar-width: thin;
}
.quickbooks-integration-row {
  cursor: pointer;
}
.form-builder-container .form-field-container-border {
  border: none;
  max-height: inherit;
  overflow: hidden;
}
.form-builder-container .form-field-container-border .add-section-button {
  border: 1px solid lightgray;
}
.selected-row {
  background-color: rgba(230, 230, 230, 0.2);
}
.selected-row:hover {
  background-color: rgba(240, 240, 240, 0.2);
}
.boards-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 0px 10px;
  display: flex;
}
.boards-container-regular {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 0px 10px;
  display: flex;
  top: 135px;
}
.boards-container-fullscreen {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 0px 10px;
  display: flex;
  top: 65px;
}
.boards-column {
  flex: 1;
  display: inline-flex;
  border: 1px lightgray solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0px 5px;
  overflow: hidden;
  min-width: 250px;
}
.boards-column-inner-container {
  width: 100%;
  background-color: white;
  padding: 2px 5px;
}
.boards-column-header {
  margin: 0px 5px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-weight: bold;
  position: sticky;
  z-index: 200;
}
.boards-card {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 5px 10px;
  flex: 1;
  min-height: 50px;
  max-height: 500px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid lightgray;
  background-color: white;
}
.boards-card-locked {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 5px 10px;
  flex: 1;
  min-height: 50px;
  max-height: 500px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid lightgray;
  background-color: white;
}
.boards-card-locked:hover {
  cursor: pointer;
}
.boards-card-draggable {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 5px 10px;
  flex: 1;
  min-height: 50px;
  max-height: 500px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid lightgray;
  background-color: white;
}
.boards-card-draggable:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.boards-card-buttons {
  height: 20px;
  padding: 0;
  width: 22px !important;
  margin-left: auto;
  margin-right: 10px;
}
.boards-card-profile-circle {
  margin: 5px 0px;
}
.boards-card-profile-circle:hover {
  cursor: help;
}
.boards-status-card {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-height: 20px;
  padding: 5px;
  margin: 20px 5px;
}
.boards-status-dragger {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.boards-status-dragger:hover {
  cursor: move;
}
.boards-setting-form {
  position: absolute;
  top: 55px;
  left: 24px;
  right: 24px;
  bottom: 53px;
  overflow: auto;
}
.board-link-view-container {
  overflow: auto;
  height: calc(100vh - 166px);
}
.board-status-configure-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: 'flex-end';
}
#board-crumb-button {
  min-width: 50px;
  cursor: pointer;
  border: 1px solid lightgray;
  background-color: white;
  padding: 0px 5px 2px 5px;
  border-radius: 4px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 36px;
  line-height: 32px;
  display: inline-block;
  vertical-align: bottom;
}
#board-status-add-button {
  position: absolute;
  right: 0px;
  top: -20px;
}
#board-card-link-button {
  width: fit-content;
  min-width: 200px;
  text-align: left;
  padding: 0px;
  font-size: 12px;
}
.board-card-timeline-container {
  position: absolute;
  padding: 20px 5px;
  left: 24px;
  right: 24px;
  top: 115px;
  overflow-y: auto;
}
.board-card-timeline-container-read {
  position: absolute;
  padding: 20px 5px;
  left: 24px;
  right: 24px;
  top: 115px;
  overflow-y: auto;
  bottom: 0px;
}
.board-card-timeline-container-write {
  position: absolute;
  padding: 20px 5px;
  left: 24px;
  right: 24px;
  top: 115px;
  overflow-y: auto;
  bottom: 53px;
}
#board-card-client-comms {
  position: absolute;
  top: 115px;
  left: 24px;
  right: 24px;
  bottom: 53px;
}
.board-card-days-in-status-container {
  position: relative;
  width: fit-content;
}
.board-card-days-in-status-text {
  margin-left: 2px;
  font-size: 10px;
}
.board-card-text-preview {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.board-list-view-container {
  width: 100%;
  overflow-y: scroll;
  margin-top: 20px;
}
.board-list-view-container-regular {
  width: 100%;
  overflow-y: scroll;
  margin-top: 20px;
  height: calc(100% - 20px);
}
.board-list-view-container-fullscreen {
  width: 100%;
  overflow-y: scroll;
  margin-top: 20px;
  height: calc(100% - 52px);
}
.board-list-view-status-section {
  background-color: white;
  border-radius: 4px;
  border: 1px lightgray solid;
  position: relative;
}
.board-list-table {
  width: 100%;
  table-layout: auto;
}
.board-list-row {
  border-bottom: 1px lightgray solid;
}
.board-list-row-title:hover {
  cursor: pointer;
}
.board-list-row-title-draggable:hover {
  cursor: grab;
}
.board-list-cell {
  padding: 0px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
}
.board-list-status-title {
  font-family: roboto-bold;
  font-size: 18px;
  margin-bottom: 5px;
  background-color: #f0f2f5;
  position: sticky;
  top: 0px;
  left: 0px;
}
.board-list-header {
  word-wrap: break-word;
  width: 300px;
  padding: 0px 5px;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 101;
}
.board-list-title-column {
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: white;
  z-index: 100;
}
.board-list-title-column-header {
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: white;
  z-index: 100;
  z-index: 200;
}
.board-list-datum {
  max-width: 500px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board-list-title-column-border {
  position: absolute;
  left: 350px;
  top: 35px;
  bottom: 0px;
  width: 1px;
  background-color: lightgray;
  z-index: 101;
}
.board-card-emails {
  margin: 10px 0px;
}
.board-card-email-row {
  border-radius: 4px;
  border: 1px solid lightgray;
  overflow: hidden;
  margin: 5px 0px;
  padding: 0px 5px;
  min-height: 40px;
}
.board-card-email-row:hover {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.board-card-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: none;
  max-width: 100%;
}
.board-card-email-sender {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: none;
}
.board-card-email-body {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: none;
  max-width: 100%;
  color: #C8C8C8;
  white-space: nowrap;
}
.board-card-email-date {
  font-size: 10px;
  line-height: 8px;
}
.board-card-email-subject {
  color: black;
}
.board-card-email-snippet {
  font-weight: 300;
}
.board-card-email-attachments {
  width: 100%;
  overflow-x: auto;
}
.board-card-email-entry {
  width: 100%;
  border-bottom: 1px solid lightgray;
  min-height: 40px;
  padding: 10px 0px;
}
.board-card-email-entry-header {
  width: 100%;
  margin-bottom: 20px;
}
.board-card-email-entry-header-clickable {
  width: 100%;
  margin-bottom: 20px;
}
.board-card-email-entry-header-clickable:hover {
  cursor: pointer;
}
.board-card-email-from {
  font-weight: bold;
}
.board-card-email-small-text {
  color: gray;
  font-size: 12px;
  font-weight: 300;
}
#board-card-open-email-button {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 100px !important;
}
.board-search-dropdown {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  max-height: 250px;
  overflow-y: auto;
}
.board-search-item-title {
  font-weight: bold;
}
.board-user-filter-scroll {
  max-height: 250px;
  width: 300px;
  overflow-y: auto;
  border-top: 1px solid lightgray;
}
#board-user-filter-selected-user-row {
  position: relative;
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
}
#board-user-filter-remove-button {
  position: absolute;
  width: 50px !important;
  top: 0px;
  right: 0px;
}
.board-large-list-view-container-regular {
  width: 100%;
  overflow-y: scroll;
  margin-top: 20px;
  background-color: white;
  height: calc(100% - 20px);
  border: 1px solid lightgray;
  overflow: hidden;
  border-radius: 6px;
}
.board-large-list-row {
  border-bottom: 1px lightgray solid;
}
.board-large-list-row:hover {
  cursor: pointer;
  opacity: 0.5;
}
#large-board-warning-container {
  width: 400px;
}
#large-board-warning-icon {
  margin: 10px;
  width: 30px;
  height: 20px;
  position: absolute;
  bottom: 10px;
}
#large-board-warning-icon:hover {
  cursor: pointer;
}
.board-import-form .ant-radio-group {
  border-radius: 10px;
}
.board-import-form .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background: #BF381D;
  border-color: #BF381D;
}
.board-import-form .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #BF381D;
}
.board-import-form .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #BF381D;
}
.board-import-form .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
  background: #BF381D;
  border-color: #BF381D;
}
.board-import-form .ant-radio-button-wrapper:hover {
  color: #BF381D;
}
.boards-tab-pane {
  padding: 0px !important;
  height: calc(100vh - 108px) !important;
  overflow-y: auto;
}
.board-card-drawer-analytics .ant-drawer-content-wrapper {
  max-width: 1400px !important;
  width: 100% !important;
}
.board-card-drawer .ant-drawer-content-wrapper {
  max-width: 1000px !important;
  width: 100% !important;
}
.filter-user-select {
  display: flex;
  flex-direction: row;
}
.filter-user-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.filter-user-select .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.filter-user-select .ant-select-selector {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.manual-entry-card {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  margin: 10px;
  border-radius: 4px;
}
.manual-entry-card-disabled {
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  margin: 10px;
  border-radius: 4px;
  opacity: 0.75;
  cursor: not-allowed;
}
.client-portal-preview-tab-title {
  height: 27px;
  margin-top: -5px;
}
#client-portal-main-container {
  position: absolute;
  inset: 0px;
}
#client-portal-header {
  width: 100%;
  height: 120px;
  padding: 0px 10px;
}
.client-portal-header-button {
  background-color: transparent;
  width: 75px;
  padding: 0px;
}
.client-portal-header-button:active,
.client-portal-header-button:focus,
.client-portal-header-button:hover {
  background-color: transparent;
}
@media screen and (max-device-width: 500px) {
  .client-portal-header-button {
    width: 50px;
    font-size: 12px;
  }
}
#client-portal-main-body {
  padding: 20px;
  min-height: calc(100vh - 270px);
  position: relative;
  height: 100%;
  overflow-y: auto;
}
.client-portal-description-header {
  font-size: 26px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}
.client-portal-profile-label {
  font-weight: bold;
}
.client-portal-card-select {
  min-width: 50px;
  max-width: 500px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 20px;
}
.client-portal-footer {
  min-height: 150px;
  width: 100%;
  position: relative;
}
#client-portal-settings-container {
  position: absolute;
  top: 60px;
  bottom: 20px;
  padding: 0px;
}
.client-portal-board-select {
  padding: 10px 20px 10px 30px;
  width: 100%;
}
.client-portal-powered-by-ontraccr {
  font-size: 14px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
#client-portal-password-button {
  position: absolute;
  right: 10px;
  top: 20px;
}
.client-portal-progress {
  max-width: 100%;
}
.client-portal-progress-text-container {
  width: 300px;
}
.client-portal-progress-title {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}
.client-portal-progress-description {
  margin-bottom: 20px;
}
#client-portal-tagline {
  font-size: 20px;
  font-family: roboto-bold;
  padding: 20px;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  word-break: break-all;
}
.form-library-add-container {
  position: absolute;
  overflow-y: auto;
  padding: 0px 12px;
  top: 56px;
  bottom: 53px;
  padding-bottom: 50px;
}
.form-library-add-section-header {
  font-weight: bold;
  margin: 10px 0px 5px 0px;
}
.form-library-add-section-subheader {
  font-weight: 400;
  color: gray;
  font-size: 14px;
  margin: 10px 0px 5px 0px;
}
.form-library-add-text {
  font-size: 12px;
}
.form-library-preview-modal {
  position: absolute;
  top: 50px;
  bottom: 50px;
  width: 400px;
  right: 810px;
  left: auto;
  background-color: white;
  border-radius: 4px;
  border: 1px solid lightgray;
  z-index: 1000;
  flex-direction: row;
  justify-content: 'center';
}
.form-library-preview-modal-container {
  height: 100%;
  overflow-y: auto;
}
.form-library-preview-image {
  max-width: 360px;
  object-fit: contain;
  margin: 20px;
}
.form-library-preview-divider {
  width: 380px;
  min-width: 380px;
  margin: 10px;
}
.analytics-chart-type-icon {
  margin-right: 5px;
}
.analytics-header {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}
.analytics-body-container {
  display: flex;
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.analytics-number-title {
  color: black;
  font-size: 18px;
}
.analytics-number-col {
  margin-bottom: 20px;
}
.analytics-number-container {
  margin-top: 20px;
}
.analytics-title-button {
  margin-left: 20px;
}
.analytics-title-button:hover {
  cursor: pointer;
  opacity: 0.85;
}
.analytics-tag-filter-icon {
  margin: 0px 5px;
  color: #bfbfbf;
}
.analytics-tag-filter-icon:hover {
  cursor: pointer;
  color: black;
  opacity: 0.45;
}
.analytics-tag-background {
  position: fixed;
  inset: 0px;
  background-color: transparent;
  z-index: 2;
}
.analytics-date-preset-tag:hover {
  opacity: 0.85;
  cursor: pointer;
}
.dashboard-component-delete-btn {
  position: absolute;
  top: 1px;
  right: 7px;
  width: 20px !important;
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-component-edit-btn {
  position: absolute;
  top: 1px;
  right: 30px;
  width: 20px !important;
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
}
#timecard-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 76vh;
  padding: 10px 0px;
}
.timecard-divider {
  background-color: #323232;
  height: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.timecard-column-changer-checkbox-group .ant-checkbox-group-item {
  display: block;
  margin-right: 0px;
}
.timecard-entry-row {
  background: white;
}
.timecard-entry-row:hover {
  cursor: pointer;
  background-color: #F8F8F8;
}
.timecard-stats-container {
  background-color: #fff;
  border-radius: 5px;
  height: 60px;
  border: 1px solid lightgray;
  text-align: center;
  transition-property: width;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.timecard-stats-button:hover {
  cursor: pointer;
}
.invoice-add-drawer {
  overflow: hidden;
}
.invoice-add-drawer-upload {
  height: 84vh;
  overflow: hidden;
}
.invoice-add-drawer-form {
  height: 84vh;
  overflow: scroll;
}
.invoices-table {
  border: 1px solid #d9d9d9;
  overflow: hidden;
}
.invoices-table .ant-table-cell {
  text-align: center;
}
.invoices-table .ant-table-body {
  min-height: calc(90vh - 160px);
}
.invoices-table .ant-table-expanded-row-fixed {
  min-height: calc(90vh - 160px);
}
.invoices-table-row {
  cursor: pointer;
}
.invoices-table-row:hover {
  background-color: rgba(230, 230, 230, 0.2);
}
.user-marker-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.user-marker-popup p {
  text-align: left;
}
.map-bolded-text {
  font-weight: bold;
}
.map-lat-lng-wrapper {
  display: flex;
  flex-direction: column;
}
.user-marker-times-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid black;
  gap: 10px;
}
.user-marker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-clock-in-out-text {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
.time-card-text {
  width: 100%;
  line-height: 10px;
}
.subtask-collapse .ant-collapse-header {
  font-weight: 650;
  background: transparent;
}
.subtask-page-table .ant-table-body {
  overflow: hidden !important;
  max-height: unset !important;
}
html,
body,
#app,
#app > div {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: white !important;
  overscroll-behavior-y: none;
  --antd-wave-shadow-color: none !important;
}
#root {
  margin: 0;
  height: 100%;
  width: 100%;
}
.Menu {
  margin: 0;
  height: 100vh;
  width: 256;
}
.productfruits--branding {
  visibility: hidden !important;
}
.dashboard-edit:focus {
  opacity: 0.65;
}
.dashboard-edit:active,
.dashboard-edit:hover,
.dashboard-edit:focus {
  color: black;
}
.ant-modal .ant-modal-confirm .ant-modal-confirm-confirm {
  width: 360px;
}
.ant-modal-confirm .ant-modal-body {
  padding: 20px;
}
.ant-modal-confirm .ant-modal-confirm-btns,
.ant-modal-confirm-body .ant-modal-confirm-content {
  text-align: center;
  width: 100%;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 20px 0px 0px 0px !important;
}
.ant-modal-confirm-body > .anticon {
  display: none;
}
.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-body,
.ant-modal-title {
  font-family: 'roboto-regular';
  font-size: 24px;
  text-align: center;
  width: 100%;
}
.ant-modal-body {
  padding: 5px;
}
#mainView {
  height: 50vh !important;
  overflow: scroll;
}
.ant-menu-item,
.ant-menu-submenu-title {
  height: 25px !important;
  line-height: 25px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 1 !important;
}
.ant-layout-sider-children {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
}
.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
}
.ant-menu-sub {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ant-breadcrumb {
  font-size: 24px;
}
.searchbar {
  border-radius: 20px;
}
.searchbar:hover {
  border-color: #323232;
}
.searchbar:active,
.searchbar:focus {
  box-shadow: none;
  border-color: inherit;
}
.sidelist-item-hoverable:hover {
  opacity: 0.66;
}
.ant-input-search-icon::before {
  border-left: none;
}
.OnTraccrInput,
.OnTraccrNumberInput {
  border-radius: 6px;
  color: #323232;
  font-family: 'roboto-regular';
  width: 100%;
}
.OnTraccrNumberInput {
  width: 80%;
  overflow: hidden;
}
.OnTraccrInput:active,
.OnTraccrInput:hover,
.OnTraccrInput:focus,
.OnTraccrNumberInput:active,
.OnTraccrNumberInput:hover,
.OnTraccrNumberInput:focus,
.ant-input-number-focused {
  border-color: #323232;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.OnTraccrInput::selection,
.OnTraccrNumberInput::selection {
  background-color: #323232;
  color: white;
}
.ant-input {
  box-shadow: none !important;
  -webkit-box-shadow: none;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler:active {
  color: #323232;
  box-shadow: none;
  -webkit-box-shadow: none;
  outline-color: #323232;
}
#mailbtn {
  background-color: #BF381D;
  border-color: #BF381D;
}
#mailbtn {
  font-size: 20px;
}
.borderlessbtn {
  border: none;
  box-shadow: none;
  width: 100%;
  font-family: roboto-light, sans-serif;
}
.borderlessbtn:hover,
.borderlessbtn:active,
.borderlessbtn:focus {
  color: inherit;
}
.borderlessbtn:hover {
  opacity: 0.66;
}
.ant-modal-content {
  border: solid;
  border-radius: 20px;
  border-color: #5C5C56;
  border-width: 0.5px;
  background-color: #F8F8F8;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-sub,
.ant-menu-dark,
.ant-menu-submenu-arrow {
  color: white !important;
  font-family: 'roboto-light';
  font-size: 14;
}
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-title:focus,
.ant-menu-dark .ant-menu-item:focus,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected span {
  color: #F2BD1E !important;
  outline: none;
}
.ant-pagination-item-active {
  background-color: #F2BD1E;
  border-color: #F2BD1E;
}
.ant-pagination-item,
.ant-pagination-item a {
  border-radius: 15px;
  border-color: #F2BD1E;
  color: #F2BD1E;
}
.ant-pagination-item:focus,
.ant-pagination-item-active:focus a,
.ant-pagination-item:focus a {
  color: #323232 !important;
  border-color: #F2BD1E;
}
.ant-pagination-item:hover,
.ant-pagination-item-active:hover a,
.ant-pagination-item:hover a {
  border-color: #323232;
  color: #323232 !important;
}
.ant-pagination-item-active a {
  color: #323232;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #323232 !important;
  background-color: 'red';
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link {
  border-color: #F2BD1E;
  color: #F2BD1E;
}
.ant-pagination-disabled .ant-pagination-item-link {
  border-color: rgba(0, 0, 0, 0.25) !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: #323232;
  border-color: #323232;
}
.ant-card-hoverable:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
}
.ant-legacy-form-item-control-wrapper,
.ant-legacy-form-item {
  width: 100%;
}
.ant-input-affix-wrapper-focused {
  border-color: inherit;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #323232;
}
.large-modal {
  width: 700px;
}
.ant-legacy-form input[type='checkbox']:focus {
  outline: none;
}
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-tree-checkbox-inner .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: #BF381D;
}
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  opacity: 0.65;
}
.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-selection-column {
  background-color: inherit;
  font-weight: 600;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #BF381D;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: inherit;
}
.ant-table-row-expand-icon,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #BF381D;
  border-color: #BF381D;
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #BF381D;
}
.ant-spin-dot-item {
  background-color: #BF381D;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-filter-trigger.active {
  color: #BF381D;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #BF381D;
  color: white;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #F2BD1E;
}
tr:hover {
  background-color: white;
}
.learning-center-button {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.65);
}
.learning-center-button:hover {
  opacity: 0.65;
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.65);
}
.learning-center-list {
  padding-left: 5px;
  padding-right: 5px;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #BF381D;
}
.bold-text {
  font-weight: 650;
}
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Load Fonts */
@font-face {
  font-family: 'roboto-light';
  src: local('roboto-light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-regular';
  src: local('roboto-regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-medium';
  src: local('roboto-medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-bold';
  src: local('roboto-bold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'raleway-medium';
  src: local('raleway-medium'), url('./assets/fonts/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'raleway-bold';
  src: local('raleway-bold'), url('./assets/fonts/Raleway-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'raleway-semibold';
  src: local('raleway-semibold'), url('./assets/fonts/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'raleway-light';
  src: local('raleway-light'), url('./assets/fonts/Raleway-Light.ttf') format('truetype');
}
